/**
 * General layout
  */

.row {
    display: flex;
    @include media-query($on-palm) {
        display: block;
    }
}


.column {
    flex: 50%;
}

/**
 * Site header
 */
.site-header {
    border-bottom: 1px solid $grey-color-light;
    min-height: $header-height;

    // Positioning context for the mobile navigation icon
    position: relative;
}

#landingcontainer {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left 50% top 50%;
    background-color: #0000009a;
    background-blend-mode: overlay;
    display: block;
    color: white;
}


.landing-grid {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    min-height: 400px;
    margin-left: auto !important;
    margin-right: auto !important;
}

@include media-query($on-palm) {
    .landing-grid > div {
        text-align: center !important;
    }
}

@include media-query($on-laptop) {
    .landing-grid > div {
        padding: 0 20px !important;
    }
}

.landing-emblem {
    flex-basis: auto !important;
    margin-top: auto;
    margin-bottom: auto;
}

.landing-emblem img {
    width: 250px;
    max-width: none;
}

.landing-textbox {
    margin-top: auto;
    margin-bottom: auto;
}

@media screen and (min-width: $on-palm) {
    .landing-textbox {
        padding: 0 20px 0 0 !important;
    }
}

#landingcontainer {
    background-image: url(../assets/microscopes_wide.jpg);
} 

#landingcontainer a {
    color: #fff;
    font-size: 110%;
}

@media (min-aspect-ratio: 1/2) {
    #landingcontainer {
        background-image: url('../assets/microscopes_wide.jpg');
        background-position: left 50% top 50%;
    } 
}

#landingcontainer img {
    margin: 10px auto 10px;
}

#logocontainer {
    margin: 10px auto 10px;
}

.site-title {
    font-size: 26px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }

    &:hover {
        text-decoration: none;
    }
}

.light {
    display: inline;
    font-weight: 300;
}

.semibold {
    display: inline;
    font-weight: 600;
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        margin-left: 20px;
    }

    /* The dropdown container */
    .dropdown {
        /*float: left;
        overflow: hidden;*/
        display: inline-block;
        padding: 0;
        position:relative
    }
    
    /* Dropdown button */
    .dropdown .dropbtn {
        border: none;
        outline: none;
        background-color: inherit;
        font-family: inherit; /* Important for vertical align on mobile phones */
    }

    /* Dropdown content (hidden by default) */
    .dropdown-content {
        display: none;
        position: absolute;
        text-align: right;
        right:-10px;
        top: 48px;
        background-color: #ffffff;
        border-radius: 5px;
        min-width: 160px;
        z-index: 1;
        box-shadow: 0 8px 20px rgba(0,0,0,0.15), 0 2px 5px rgba(0,0,0,0.12);
    }
    
    /* Links inside the dropdown */
    .dropdown-content a {
        float: none;
        display: block;
        padding: 5px 10px;

        &:not(:last-child) {
            margin-right: 0;
        }
    }
    
    /* Add a grey background color to dropdown links on hover */
    .dropdown-content a:hover {}
    
    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border-radius: 5px;
        text-align: right;
        line-height: normal;

        .dropdown-content {
            right: 140px;
            top: 0;
        }

        .dropdown {
            display: block;
        }

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;
            box-shadow: none;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
            width: 150px;
        }

        @media(hover:hover){
            &:hover{
                box-shadow: 0 8px 20px rgba(0,0,0,0.15), 0 2px 5px rgba(0,0,0,0.12);
                .trigger {
                    display: block;
                    padding-bottom: 5px;
                }
            }
        }

        @media(hover:none){
            .show-nav{
                box-shadow: 0 8px 20px rgba(0,0,0,0.15), 0 2px 5px rgba(0,0,0,0.12)  !important;
            }
            .show-navbox {
                display: block !important;
                padding-bottom: 5px !important;
            }
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;
        margin-top: 25px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 22px;

        @include media-query($on-laptop) {
            font-size: 20px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}
