ul.vendor-details {
  list-style-type: none;
  margin-left: 0;
  word-wrap: break-word;
}

ul.vendor-details li::before {
  font-family: 'Material Symbols Outlined';
  font-size: 150%;
  vertical-align: middle;
  display: inline-block;
}

ul.vendor-details li {
  margin-left: 0;
}

li.home::before {
  content: 'home';
}

li.mail::before {
  content: 'mail';
}

li.location::before {
  content: 'location_on';
}

li.shipping::before {
  content: 'local_shipping';
}

li.product_page::before {
  content: 'package_2';
}

.contribution-icons-container {
  display:flex;
}

.vendor-details-container {
  display:flex;
  flex: 1 1 0;
  flex-wrap: wrap;
  margin: 5px;
}

.contribution-icon {
  font-size:64px;
  color:rgb(20, 124, 20);
}
.contribution-icon:hover .tooltiptext {
  visibility: visible;
}

.contribution-icon .tooltiptext {
  width: 200px;
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size:16px;
  position: absolute;
  white-space:pre-wrap;
  border: solid black 1px;
  border-radius: 5px;
  padding: 5px;
  transform: translate(-100px,-50px)
}

.highlight-image {
  height: 180px;
  margin: 5px;
}

.embedded-youtube {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 40px 0px;
}

.embedded-youtube iframe, .embedded-youtube object, .embedded-youtube embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-100{
  width: 100% !important;
}

.funders-container {
  display:flex;
  flex: 1 1 0;
  flex-wrap: wrap;
  margin: 5px;
}

.funder-container {
  width: 250px;
  text-align: center;
}

.image-funders{
  max-height: 125px;
  max-width: 250px;
  padding: 20px;
}

.os-download{
    min-width: 50%;
}
