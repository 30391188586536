.float-left {
    display: block;
    float: left;
    margin: 0 25px 25px 0;
}

.float-right, .tweetbox {
    display: block;
    float: right;
    margin: 0 0 25px 25px;
}

.float-center {
    display:block;
    margin-left: auto;
    margin-right: auto;
}

.image-small, .width-small {
    width: 125px !important;
}

.image-s-m, .width-s-m {
    width: 200px !important;
}

.image-s-m-fixed {
    width: 200px !important;
    min-width: 200px;
}

.image-medium, .width-medium {
    width: 250px !important;
}

.image-m-l, .width-m-l {
    width: 300px !important;
}

.image-fairly-large {
    width: 400px !important;
}

.image-large, .width-large {
    width: 500px !important;
}

.image-x-large, .width-x-large {
    width: 700px !important;
}

.image-xx-large, .width-xx-large {
    width: 800px !important;
}

.image-inset-bottom {
    margin-bottom: -30px;
}

.image-large, .image-medium, .image-small, .tweetbox, .elevated {
    box-shadow: $elevated-shadow;
    border-radius: $corner-radius;
}

.no-shadow {
    box-shadow: none !important;
}

.tweetbox {
    width: 352px;
}

@include media-query($on-palm) {
    .image-medium, .image-large, .tweetbox {
        width: 100%;
    }
}

.flex-container, .flex-container-tight, .flex-container-very-tight {
    display: flex;
    margin: 0;
}

.flex-valign-center {
    align-items: center;
}

.flex-grow {
    flex-grow: 1 !important;
}

.flex-content {
    flex-basis: content !important;
}

.flex-min-width-zero {
    min-width: 0;
}

.flex-auto {
    flex-basis: auto !important;
}

@include media-query($on-palm) {
    .flex-container, .flex-container-tight, .flex-container-very-tight {
        flex-direction: column;
    }
    // Hacky way to stop flex-container-horizontal ever switching
    .flex-container-horizontal {
        flex-direction: row !important;
    }
}

.flex-container-vertical {
    flex-direction: column;
}

.flex-container > div {
    flex-basis: 100%;
}

.flex-container-tight > div {
    margin: 10px 20px 10px 0;
    padding: 10px 40px 10px 0;
    flex-basis: auto;
    text-align: left;
}

.flex-container-very-tight > div {
    margin: 0 10px 10px 0;
    padding: 0 10px 0 0;
    flex-basis: auto;
    text-align: left;
}

.padding-v-small {
    padding: 10px !important;
}

.padding-small {
    padding: 20px !important;
}

.margin-remove {
    margin: 0 !important;
}

.margin-bottom-small {
    margin-bottom: 20px !important;
}

.margin-right-small {
    margin-right: 20px !important;
}

.margin-left-small {
    margin-left: 20px !important;
}

.margin-right-very-small {
    margin-right: 10px !important;
}

.margin-left-very-small {
    margin-left: 10px !important;
}


.text-center {
    text-align: center !important;
}

.download-btn {
    display: inline-block;
    width: fit-content;
    padding: 10px;
    background-color: $brand-color;
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: $base-shadow;
    border-radius: $corner-radius;
    transition: box-shadow 0.2s ease-in-out;
}

.download-btn:hover {
    box-shadow: $elevated-shadow;
}

.download-btn.outline {
    background-color: transparent;
    border: 1px solid $brand-color;
    color: $brand-color !important;
    transition: box-shadow 0.2s ease-in-out;
}

.big-icon {
    font-size:64px;
    color: $brand-color;
}

.fairly-big-icon {
    font-size:55px;
    color: $brand-color;
}

.hint-text {
    margin: 0 -10px;
    color: $grey-color;
    font-size: $small-font-size;
}

.styz-image {
    display: inline-block;
    margin: 10px 0 20px 0;
}

.styz-image img {
    height: 200px;
}

.text-center {
    text-align: center
}

.break-words {
    word-wrap: break-word;
}