@charset "utf-8";



// Our variables
$base-font-family: 'Open Sans', sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$header-height: 56px;

$spacing-unit:     30px;

$text-color:              #111;
$background-color:        #ffffff;
$brand-color:             rgba(197, 36, 127, 1);
$brand-color-transparent: rgba(197, 36, 127, 0.12);

$grey-color:       #505050;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Elevation
$elevated-shadow: 0 8px 20px rgba(0, 0, 0, 0.15), 0 2px 5px rgba(0, 0, 0, 0.12);
$base-shadow: 0 4px 10px rgba(0, 0, 0, 0.15), 0 2px 5px rgba(0, 0, 0, 0.12);

$elevated-shadow-brand-color: 0 8px 20px rgba(0, 0, 0, 0.15), 0 2px 5px $brand-color;
$base-shadow-brand-color: 0 4px 10px $brand-color-transparent, 0 2px 5px $brand-color-transparent;

// Corner styles
$corner-radius: 4px;

// Width of the content area
$content-width:    900px;

$on-palm:          950px;
$on-laptop:        950px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "helper",
        "syntax-highlighting",
        "custom"
;
